
import React, { useState, useEffect } from 'react';

import { useStore } from '../../../../zustand/store';
import {
  initManageElement,
  addNewElement,
} from '../editor/manageElement'
import PixaStock from '../../../scheduler/components/PixaStockComponant';
import AiImage from '../../../scheduler/components/AiImageComponant';
import Mylibrary from '../../../../components/common/myLibrary/MylibraryComponant';
import schedulerSvg from '../../../scheduler/components/schedulerSvg';
import ThirdPartyMedia from '../../../../components/common/thirdPartyMedia/Media'


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { setMyState, hitApi, commonToast } from '../../../../components/commonAction';

const ImageSetting = () => {


  let store = useStore(state => state),

    editorData = store.editorData || []


  initManageElement(editorData, store.updateStore);


  let [state, setQuery] = useState({
    platform: 'pexels',
    imagesList: [],
    isLoading: false,
    keyword: '',
    page: 1,
    totalRecords: 0,
    mediaUrl: {},
  });

  const [myLibraryToggle, setmyLibraryToggle] = useState(false);


  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // range

  const [value, setValue] = React.useState(30);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };


  let checkMedia = (elements, index = 0) => {
    manageMedia({
      elements, index
    }, (frmData) => {
      hitApi({
        method: 'POST',
        url: 'media?mediaType=image',
        data: frmData,
        isFormData: true,
      }, (resp) => {
        setMyState(setQuery, {
          page: 1
        })
        fileterImages("search")
      });
    });
  }

  let manageMedia = ({ elements, index }, cb) => {

    if (!elements.target || !elements.target.files) {
      commonToast('error', `Please choose a file to continue.`);
      return;
    }

    let selectedFile = elements.target.files[index ? index : 0];
    let selFileType = selectedFile.type;
    let selFileExt = "." + selectedFile.name.split(".").reverse()[0].toLowerCase();

    let acceptAry = elements.target.accept.replace(new RegExp(" ", "g"), "").split(",");

    let acceptFileTypeAry = [];
    let acceptFileAry = [];
    acceptAry.map((d, i) => {
      let fd = d.split("/");
      acceptFileTypeAry.push(fd[0]);
      acceptFileAry.push(fd[1]);
    });

    if (!acceptAry.includes(selFileExt)) {
      commonToast('error', `Only ${acceptAry.join(", ") + (acceptFileTypeAry.length == 1 ? " file is " : " files are ")} allowed.`);
    } else if (selectedFile) {
      let data = new FormData();
      let fr = new FileReader();

      if (selFileType.split("video").length > 1 || selFileType.split("image").length > 1) {
        fr.onloadend = async (e) => {
          e.currentTarget.value = "";
          let name = selectedFile.name;
          name = name.replace(/\s/g, "-");
          let thumbName = getFileName(name) + `-thumb.png`;
          data.append("file", selectedFile, name);
          var createElem = document.createElement(`img`);
          createElem.src = URL.createObjectURL(selectedFile);
          createElem.onload = (e) => {
            var mediaMeta = {
              duration: createElem.duration,
              width: createElem.width,
              height: createElem.height,
            };
            data.append("meta", JSON.stringify(mediaMeta));

            cb(data);
          };
        }

        fr.readAsDataURL(selectedFile);
      } else {
        commonToast('error', `Selected file not allowed.`);

      }
    } else {
      commonToast('error', `Please choose a file.`);
    }
  }

  let getFileName = (filename) => {
    return filename.replace(/\.[^/.]+$/, "");
  }


  useEffect(() => {
    fileterImages();
  }, []);

  let fileterImages = (type = null) => {


    if (state.isLoading) {
      return;
    }

    let currentPage = type == 'loadMore' ? state.page + 1 : (type == 'search' ? 1 : state.page);
    setMyState(setQuery, {
      isLoading: true
    })
    //   hitApi({
    //       method: 'GET',
    //       url: 'media',
    //       data: {
    //           mediaType: "image",
    //           page: currentPage,
    //           limit: 9
    //       },
    //   }, (resp) => {
    //       setMyState(setQuery, {
    //           imagesList: type == 'loadMore' ? [...state.imagesList, ...resp.data] : resp.data,
    //           isLoading: false,
    //           totalRecords: resp.totalRecords,
    //           page: currentPage
    //       }, () => {
    //       })
    //   }, () => {
    //       setMyState(setQuery, {
    //           isLoading: false
    //       })
    //   });
  }

  return (
    <>
      <div className={`cun-post-right ${myLibraryToggle ? 'openLibrarySidebar' : 'closeLibrarySidebar'}`}>
        {/* <div className="sch-post-arrow-responsive">
          <a onClick={() => setmyLibraryToggle(!myLibraryToggle)}>{schedulerSvg.app.Arrow}</a>
        </div> */}
        <div className={`sch-right-inner sch-editorBox`}>
          <div className="sch-library-tab">
            <Tabs defaultActiveKey="tab-pixastock" id="justify-tab-example" transition={false}>
              <Tab eventKey="tab-pixastock" title="PixaStock">
                <div className="sch-library-tab-inner" id="pixastock_div">
                  <PixaStock
                    baseClass="sortPage"
                    mediaType="image"
                    onClickAct={(data) => {
                      addNewElement({
                        type: "image",
                        src: data.path,
                        meta: {
                          height: data.original.height,
                          width: data.original.width
                        }
                      });
                    }}
                  />
                </div>
              </Tab>
              <Tab eventKey="tab-ai-image" title="AI Image">
                <div className="sch-library-tab-inner">
                  <AiImage />
                </div>
              </Tab>
              <Tab eventKey="tab-apps" title="Apps">
                <div className="sch-library-tab-inner">
                  <ThirdPartyMedia
                    baseClass="sortPage"
                    mediaType="image"
                    onClickAct={(data) => {
                      addNewElement({
                        type: "image",
                        src: data.path,
                        meta: {
                          height: data.original.height,
                          width: data.original.width
                        }
                      });
                    }}
                  />
                </div>
              </Tab>
              <Tab eventKey="tab-my-library" title="My Library">
                <div className="sch-library-tab-inner">
                  <Mylibrary
                    dataPerPage='15'
                    mediaType="image"
                    baseClass="sortPage"
                    onClickAct={(data) => {
                      addNewElement({
                        type: "image",
                        src: data.path,
                        meta: {
                          height: data.original.height,
                          width: data.original.width
                        }
                      });
                    }}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>

  )
}

export default ImageSetting