let appMode = process.env.REACT_APP_APP_MODE; console.log(appMode,"appMode");
export default {
    appName : "PixaLab",
    appLogo : "https://cdn.pixalab.ai/assets/logo.png",
    schedulerLogo:'/assets/images/logoSchedular-service.svg',
    imagesLogo:'/assets/images/logoImage-service.svg',
    supportLogo:'/assets/images/logo-aiSupport-service.svg',
    builderLogo:'/assets/images/logo-builder-service.svg',
    appFevicaon : "https://cdn.pixalab.ai/assets/favicon.png",
    cdnPath :   `https://cdn.pixalab.ai/`,
    // cdnPath :   `https://cdn.pixalab.ai/${appMode == 'demo' ? "" : "medias/"}`,
    apiUrl : appMode == 'live' ? "https://api.pixalab.ai/" : "http://localhost:3001/",
    appUrl : appMode == 'live' ? "https://app.pixalab.ai/" : "https://dev.pixalab.ai/",
    builderUrl : appMode == 'live' ? "https://builder.pixalab.ai/" : "http://devbuilder.pixalab.ai/",
};