// import Sidebar from "../components/Sidebar";
import AddFbBtn from "../components/common/SocialAccount/Facebook";
import LinkedinBtn from "../components/common/SocialAccount/linkedin";
import PinterestBtn from "../components/common/SocialAccount/pinterest";
import GoogleBtn from "../components/common/SocialAccount/Google";
// import TiktokBtn from "../components/common/SocialAccount/Tiktok";
import { useState, useEffect } from "react";
import { Loader, hitApi, setMyState, skeletonLoader, teamMemberRestrictionMess } from '../components/commonAction';

import { useNavigate } from "react-router-dom";
import ConfirmationPopup from "../components/common/ConfirmationPopup";
import { useStore } from "../zustand/store";
import appConfig from "../config/appConfig";
import {Helmet} from "react-helmet";
import { ToastContainer } from "react-toastify";



const Social = ({ pageTitle }) => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        isLoading: false,
        accountsList: [],
        activeAccount: 'facebook',
        currentList: [],
        fbPages: [],
        removeMe: false,
        deleteId: ''
    });
   const [loadaccounts, setloadaccounts]=useState(false)
    let store = useStore((state) => state);
    let {role, scope } = store.userData;
    useEffect(()=>{
        setMyState(setState, {
            activeAccount:  window.location.hash.substring(1) ? window.location.hash.substring(1) : 'facebook',
            currentList: state.accountsList.filter(d => d.source == window.location.hash.substring(1))
        });
    },[])

    let filterAccounts = () => {
        setMyState(setState, { isLoading: true });
        hitApi({
            url: 'social-account/get-accounts',
            method: "GET",
            data: {
                limit: 'all',
                page: 1,
            }
        }, (resp, err = null) => {
            setMyState(setState, { isLoading: false });
            if (!err) {
                setMyState(setState, {
                    accountsList: resp.data.records,
                    currentList: resp.data.records.filter(d => d.source == state.activeAccount)
                });
            }
        });
    }

    useEffect(() => {
        setMyState(setState, {
            currentList: state.accountsList.filter(d => d.source == state.activeAccount)
        });
    }, [state.activeAccount])

    useEffect(() => {
        filterAccounts();
    }, [loadaccounts]);


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        console.log({searchParams})
        const code = searchParams.get('code');
        const id = searchParams.get('id');
        console.log({id}, window.location.search,"window.location.search")
        let sourceAcc = searchParams.get('state');
        console.log({sourceAcc})
        let acc=JSON.parse(sourceAcc)
        console.log({acc})
        if (code) {
            let redirectUri =  `${appConfig.appUrl}social`;
            hitApi({
                url: 'social-account/add-account',
                method: "POST",
                data: {
                    source: acc?.social,
                    code: code,
                    redirect_uri: redirectUri,
                    id : acc?.id
                }
            }, (resp, err = null) => {
             
                setMyState(setState, { activeAccount: sourceAcc?.social });
                navigate('/scheduler/social-integration')
                filterAccounts();
            });
        }

    }, []);

    const containsSchedularWrite = (role === "teamMember") ? scope?.scheduler?.schedule.includes("write") : (role === 'client')  ? false :  true;
    const containsSchedularDelete = (role === "teamMember") ? scope?.scheduler?.schedule.includes("delete") :  (role === 'client')  ? false : true;

   
    return (
        <>
        <ToastContainer/>
          {store.pageLoading && <Loader/>}
            <div className="pxl_mainWrapper">
                
                <Helmet >
                    <title>{pageTitle}</title>
                </Helmet>
                {/* <Sidebar /> */}
                {/* <div className="pxl_rightBar_assets"> */}

                   <div className="pxlSocial-accountMain-wrapper-box">
                   <div className="pxl_social_wrapper">
                        <div className="pxl_social_container pxl-social-integration-wrapper">
                          
                            <h4 className="pxl_heading">
                                Connect your social accounts
                            </h4>
                           
                                <div className="pxl_social_platforms">
                                <ul>
                                    <li>
                                        <a className={`pxl_fb ${state.activeAccount == 'facebook' && 'pxl_account_selected'}`} onClick={e => {
                                            setMyState(setState, {
                                                activeAccount: 'facebook'
                                            });
                                        }}>
                                            <span className="pxl_social_icon">
                                                <img src="/assets/images/social/fb.svg" alt="" />
                                            </span>
                                            {/* Facebook */}
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`pxl_insta ${state.activeAccount == 'instagram' && 'pxl_account_selected'}`} onClick={e => {
                                            setMyState(setState, {
                                                activeAccount: 'instagram'
                                            });
                                        }}>
                                            <span className="pxl_social_icon">
                                                <img src="/assets/images/social/insta.svg" alt="" />
                                            </span>
                                            {/* Instagram */}
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`pxl_linkedin ${state.activeAccount == 'linkedin' && 'pxl_account_selected'}`} onClick={e => {
                                            setMyState(setState, {
                                                activeAccount: 'linkedin'
                                            });
                                        }}>
                                            <span className="pxl_social_icon">
                                                <img src="/assets/images/social/linkedin.svg" alt="" />
                                            </span>
                                            {/* Linkedin */}
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`pxl_pinterest ${state.activeAccount == 'pinterest' && 'pxl_account_selected'}`} onClick={e => {
                                            setMyState(setState, {
                                                activeAccount: 'pinterest'
                                            });
                                        }}>
                                            <span className="pxl_social_icon">
                                                <img src="/assets/images/social/pinterest.svg" alt="" />
                                            </span>
                                            {/* Pinterest */}
                                        </a>
                                    </li>
                                    
                                    
                                    
                                    <li>
                                        <a className={`pxl_google ${state.activeAccount == 'youtube' && 'pxl_account_selected'}`} onClick={e => {
                                            setMyState(setState, {
                                                activeAccount: 'youtube'
                                            });
                                        }}>
                                            <span className="pxl_social_icon">
                                                <img src="/assets/images/social/YT.svg" alt="" />
                                            </span>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a className={`pxl_tiktok ${state.activeAccount == 'tiktok' && 'pxl_account_selected'}`} onClick={e => {
                                            setMyState(setState, {
                                                activeAccount: 'tiktok'
                                            });
                                        }}>
                                            <span className="pxl_social_icon">
                                                <img src="/assets/images/social/tiktok.svg" alt="" />
                                            </span>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                           
                            

                            <div className="pxl_social_acc_card">
                                <h4 className="mb-3"><span className="pxlText-capital">{state.activeAccount}</span> accounts</h4>
 
                                {
                                    state.isLoading ? <div style={{textAlign:'center',marginBottom:'20px'}}> {skeletonLoader('list' , 2 ,'290px')}</div> : <>
                                    
                                       {/* No Account Found Box */}
                                {
                                    !state.currentList.length ?
                                        <div className="pxl_social_no_users">
                                            <div className="pxl_sm_img">
                                                <img src="/assets/images/empty-icon.png" alt="" />
                                            </div>
                                            <h4>No account added</h4>
                                        </div>
                                        : <></>
                                }


                                {/* Has Users Box */}
                                <div className="pxl_social_acc_users pxl_socialAccountWrap">
                                   
                                    <ul>
                                        {
                                            state.currentList.map((item,index) => (
                                               
                                                <li key={index} >
                                                    <a href={item.url}>
                                                       <div className="sch-accountName">
                                                            <img src={item?.data?.profile_image || item?.data?.profile  || item?.data?.picture || `/assets/images/default-img/${state.activeAccount}-default-img.png`} alt="" />
   
                                                            <span className="pxl_social_username">
                                                                {item?.data?.name}
                                                            </span>
                                                       </div>
                                                       {/* //reconnect */}
                                                                                                            
                                                        {state.activeAccount == 'facebook' &&  <div className="pxl_btn_wrap">
                                                                <AddFbBtn title="Add New Account"
                                                                    source="facebook"
                                                                    currentList={state.currentList}
                                                                    accountsList={state.accountsList}
                                                                    allaccountlistupdate = {data => {
                                                                        setMyState(setState, {
                                                                            accountsList : data
                                                                        });
                                                                    }}
                                                                    index={index} 
                                                                    parent={'reconnect'}
                                                                    onUpdate={data => {
                                                                        setMyState(setState, {
                                                                            currentList: data
                                                                        });
                                                                    }}
                                                                    containsSchedularWrite={containsSchedularWrite} /> </div>}

                                                            {state.activeAccount == 'instagram' &&  <div className="pxl_btn_wrap">
                                                                <AddFbBtn title=" Add New Account" source="instagram"  index={index} currentList={state.currentList}
                                                                 accountsList={state.accountsList}
                                                                 allaccountlistupdate = {data => {
                                                                     setMyState(setState, {
                                                                         accountsList : data
                                                                     });
                                                                 }} 
                                                                parent={'reconnect'}
                                                                onUpdate={data => {
                                                                        setMyState(setState, {
                                                                            currentList: data
                                                                        });
                                                                        
                                                                    }} containsSchedularWrite={containsSchedularWrite}
                                                                /> </div>}

                                                            {state.activeAccount == 'linkedin' &&  <div className="pxl_btn_wrap">
                                                                <LinkedinBtn currentAccount={state.currentList[index]} title=" Add New Account" parent={'reconnect'}  containsSchedularWrite={containsSchedularWrite}/> </div>
                                                            }

                                                            {state.activeAccount == 'pinterest' &&  <div className="pxl_btn_wrap">
                                                                <PinterestBtn currentAccount={state.currentList[index]} title=" Add New Account" parent={'reconnect'}  containsSchedularWrite={containsSchedularWrite}/> </div>
                                                            }
                                                             {state.activeAccount == 'youtube'&&   <div className="pxl_btn_wrap">
                                                             <GoogleBtn parent={'reconnect'} currentAccount={state.currentList[index]} title=" Add New Account" load={setloadaccounts} containsSchedularWrite={containsSchedularWrite} /> </div>
                                                               }
                                                        <div className={`pxl-reload-tooltip  pxl-deleteToltip ${containsSchedularDelete ? "" : 'pxl-tooltip-btn-diable'}`} >
                                                            <button className={`cun-socialIcon cun-socail-delete  ${containsSchedularDelete ? '' : 'sch-btn-disbale'}`}  disabled={!containsSchedularDelete} onClick={() => setMyState(setState, { removeMe: true, deleteId: item._id })}>
                                                                <img src="/assets/images/delete-whitesvg.svg" alt=""/>
                                                                <div className="cun-show-tooltip">{containsSchedularDelete ? <p>Delete</p> : teamMemberRestrictionMess(containsSchedularDelete)}
                                                                </div>
                                                            </button>
                                                              
                                                        {/* <img src="/assets/images/delete-whitesvg.svg" alt=""/> */}
                                                        </div>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                    </>
                                }

                             

                                {/* Add account Btn  */}
                                {state.activeAccount == 'facebook'&&  <div className="pxl_btn_wrap">
                                    <AddFbBtn title=" Add New Account"
                                        source="facebook"
                                        currentList={state.currentList}
                                        accountsList={state.accountsList}
                                        allaccountlistupdate = {data => {
                                            setMyState(setState, {
                                                accountsList : data
                                            });
                                        }} 
                                        onUpdate={data => {
                                            setMyState(setState, {
                                                currentList: data
                                            });
                                        }}  
                                        containsSchedularWrite={containsSchedularWrite} /> </div>}

                                {state.activeAccount == 'instagram' &&  <div className="pxl_btn_wrap">
                                    <AddFbBtn title=" Add New Account" 
                                    source="instagram" 
                                    accountsList={state.accountsList}
                                    allaccountlistupdate = {data => {
                                        setMyState(setState, {
                                            accountsList : data
                                        });
                                    }} 
                                    currentList={state.currentList}
                                        onUpdate={data => {
                                            setMyState(setState, {
                                                currentList: data
                                            });
                                        }}
                                         containsSchedularWrite={containsSchedularWrite}
                                    /> </div>}

                                {state.activeAccount == 'linkedin'  &&  <div className="pxl_btn_wrap">
                                    <LinkedinBtn title=" Add New Account"  containsSchedularWrite={containsSchedularWrite}/> </div>
                                }

                                {state.activeAccount == 'pinterest'&&   <div className="pxl_btn_wrap">
                                    <PinterestBtn title=" Add New Account"  containsSchedularWrite={containsSchedularWrite}/> </div>
                                }
                                
                                {state.activeAccount == 'youtube'&&   <div className="pxl_btn_wrap">
                                    <GoogleBtn title=" Add New Account" load={setloadaccounts} containsSchedularWrite={containsSchedularWrite} /> </div>
                                }

                                {/*state.activeAccount == 'tiktok' && <div className="pxl_btn_wrap">
                                    <TiktokBtn title=" Add New Account" /> </div>
                                */}
                            </div>
                        </div>
                    </div>
                   </div>

                {/* </div> */}
            </div>


            <ConfirmationPopup
                shownPopup={state.removeMe}
                closePopup={() => {
                    setMyState(setState, {
                        removeMe: false,
                        deleteId: ''
                    })
                }}
                type={"social account"}
                title={''}
                subTitle={''}
                yesBtn={'Delete'}
                removeAction={() => {
                    let data = {}
                    data['target'] = state.deleteId

                    hitApi({
                        url: 'social-account/remove-account',
                        method: "DELETE",
                        data: data,
                    }, (resp, err = null) => {
                        if (resp) {
                            setMyState(setState, { isLoad: !state.isLoad });
                            filterAccounts();
                        }
                        setMyState(setState, {
                            removeMe: false,
                            deleteId: ''
                        })
                    })
                }}
            />

        </>
    )
};
export default Social;