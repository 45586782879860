import React, { useState, useEffect, useRef } from "react";
import svg from "../editorSvg";
import { commonToast, hitApi, setMyState } from "../../../../components/commonAction";
import { useStore } from "../../../../zustand/store";


import PixaStock from '../../../scheduler/components/PixaStockComponant';
import AiImage from '../../../scheduler/components/AiImageComponant';

import Mylibrary from '../../../../components/common/myLibrary/MylibraryComponant';
import ThirdPartyMedia from '../../../../components/common/thirdPartyMedia/Media'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useParams } from "react-router-dom";

import { HexColorPicker, HexColorInput } from "react-colorful";
import { HuePicker, SketchPicker, TwitterPicker } from 'react-color'

const Default_color = [
  "#EF5350",
  "#EC407A",
  "#AB47BC",
  "#7E57C2",
  "#D4E157",
  "#FFEE58",
  "#FFA726",
  "#FF7043",
  "#8D6E63",
  // "#78909C",
];


let Settings = (props) => {
  const [color, setColor] = useState("#aabbcc");
  const { id } = useParams();
  const [edit, setedit] = useState(false)
  const [name, setname] = useState(props.name)
  const [btnLoader, setbtnLoader] = useState(false)
  const [colorPicker, setcolorpicker] = useState(false); 
  const [secondaryPicker, setsecondaryPicker] = useState(false); 
  const [primaryPicker, setprimaryPicker] = useState(false); 

  const menuRef = useRef();
  const bgRef = useRef();
  const pRef = useRef();
  const primaryRef = useRef();
  const sRef = useRef();
  const secondaryRef = useRef();

  function useOutsideClick(ref,picker, handler) {
    
    useEffect(
        () => {
            const listener = (event) => {

                if (!ref.current || ref.current.contains(event.target) || !picker.current ||  picker.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },

        [ref, handler]
    );
}

  useOutsideClick(menuRef,bgRef, () =>  setcolorpicker(false));
  useOutsideClick(pRef,primaryRef, () =>  setprimaryPicker(false));
  useOutsideClick(sRef,secondaryRef, () =>  setsecondaryPicker(false));

  const [grad, setgrad] = useState({
    primary: "",
    secondary: "",
    angle: 0
  });

  const allLayout = {
    Square: {
      width: "650px",
      height: "650px",
    },
    Landscape: {
      width: "1156px",
      height: "650px",
    },
    Portrait: {
      width: "366px",
      height: "650px",
    },
  };

  const [size, setsize] = useState({
    layout: "",
    height: "",
    width: ""
  })
  useEffect(() => {
    if (props.layout == "Custom") {
      setsize({
        layout: props.layout,
        height: props.size.height,
        width: props.size.width,
      })
    } else {
      setsize({
        layout: props.layout,
      })
    }
  }, [])

  let [state, setQuery] = useState({
    imagesList: [],
    isLoading: false,
    keyword: '',
    page: 1,
    totalRecords: 0,
  });

  const [ToggleState, setToggleState] = useState(1);
  let store = useStore((state) => state);
  let drawData = useStore((state) => state.drawData);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const changeValue = async (key, value) => {
    let data = {
      ...drawData,
      [key]: value,
    };
    await store.updateStore("drawData", data);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  useEffect(() => {
    if (ToggleState == 3 && !drawData.bgColor.includes("url")) {
      let color = drawData.bgColor.split(",");
      if (color.length) {
        const regex = /linear-gradient\((\d+deg)/;
        const match = drawData.bgColor.match(regex);
        setgrad({
          primary: color[1],
          secondary: color[2].replace(")", ""),
          angle: 0
        });
      }
    }
  }, [ToggleState]);



  const renameTemplate = (e) => {
    if (name.trim() == "") {
      commonToast('error', `Title is require.`);
      return
    }

    let apiUrl;
    if (store?.userData?.role == 'admin') {
      apiUrl = "image/template/update-template"
    }
    else {
      apiUrl = "image/my-designs/update-design"
    }
    setbtnLoader(true)
    hitApi(
      {
        method: "PATCH",
        url: apiUrl,
        data: {
          target: id,
          title: name,
        },
      },
      async (resp) => {
        setbtnLoader(false)
        if (resp.status) {
          props.setname(name)
          setedit(false)
        }
      }
    );

  }

  const changeLayout = () => {
    if (size.height == "") {
      commonToast('error', `Height is require.`);
      return
    }
    if (size.width == "") {
      commonToast('error', `Width is require.`);
      return
    }
    if (size.height < 100) {
      commonToast('error', `Height  must be greater than 100px.`);
      return
    }

    if (size.height > 2000) {
      commonToast('error', `Height must be less than 2000px.`);
      return
    }


    if (size.width == "") {
      commonToast('error', `Width is require.`);
      return
    }

    if (size.width < 100) {
      commonToast('error', `Width must be greater than 100px .`);
      return
    }

    if (size.width > 1120) {
      commonToast('error', `Width must be less than 1120px .`);
      return
    }
    props.setsize({
      layout: "Custom",
      height: size.height,
      width: size.width
    })

  }


  const colorPickerChange = (type, color) => {

    if(type == 'primary_color')
    {
      setgrad({
        primary: color.hex,
        secondary: grad?.secondary,
        angle: grad?.angle
      });
      changeValue("bgColor", `linear-gradient(${grad?.angle}, ${color.hex}, ${grad?.secondary ? grad?.secondary : "white"}`);
    }
    else if(type == 'secondary_color')
    {
      setgrad({
        primary: grad?.primary,
        secondary: color.hex,
        angle: grad?.angle
      });
      changeValue("bgColor", `linear-gradient(${grad?.angle}, ${grad?.primary ? grad?.primary : "white"}, ${color.hex})`);
    }
    else if(type == 'bg_color')
    {
      changeValue(
        "bgColor",
        `linear-gradient(0deg, ${color.hex}, ${color.hex})`
      );
    }

  }
  const colorPickerComplete = (type, color) => {
    if(type == 'primary_color')
    {
      setgrad({
        primary: color.hex,
        secondary: grad?.secondary,
        angle: grad?.angle
      });
      changeValue("bgColor", `linear-gradient(${grad?.angle}, ${color.hex}, ${grad?.secondary ? grad?.secondary : "white"}`);
    }
    else if(type == 'secondary_color')
    {
      changeValue("bgColor", `linear-gradient(${grad?.angle}, ${grad?.primary ? grad?.primary : "white"}, ${color.hex})`);
    }
    else if(type == 'bg_color')
    {
      changeValue(
        "bgColor",
        `linear-gradient(0deg, ${color.hex}, ${color.hex})`
      );
    }
  }


  return (
    <>
      <div className="p-25">
        <div className="cun_img_editor_side_layers">
          <h6> Settings</h6>
        </div>

        <div className="cun-settingTitle pb-3">
          <label className="cun_image_editor_label pb-2"> Title </label>
          <div className="">
            <div className="ewp_input d-flex gap-2  w-100">
              <div className="cun_editor_setting_input">

                <input value={name} type="text" placeholder="Enter Title" onChange={(e) => {
                  setname(e.target.value)
                }}></input>

              </div>

              <button disabled={btnLoader ? true : false} onClick={(e) => {
                renameTemplate(e)

              }} className="cun_image_creator_linear_btn "> {btnLoader ? 'Processing...' : 'Update'}</button>


              {/* {edit ?
                <button onClick={(e) => {
                  renameTemplate(e)

                }} className="cun_image_creator_linear_btn "> Update</button>
                : <button onClick={() => {
                  setedit(true)
                }} className="cun_image_creator_linear_btn ">edit </button>} */}
            </div>
          </div>
        </div>
        <div>
          <div className="cun-settingResize pb-3">
            <label className="cun_image_editor_label pb-2"> Resize </label>
            <div className="">
              <div className="cun_editor_chose_size_box">
                <div className={"cun_editor_square".concat(size.layout == "Square" ? " cun_editor_square_active " : "")} onClick={() => {
                  let fs = allLayout["Square"]
                  props.setsize({
                    ...fs,
                    layout: "Square"
                  })
                  setsize({
                    ...size,
                    layout: "Square"
                  })
                }}>
                  {svg.app.Square} <span>Square</span>
                </div>
                <div className={"cun_editor_square".concat(size.layout == "Portrait" ? " cun_editor_square_active " : "")} onClick={() => {
                  let fs = allLayout["Portrait"]
                  props.setsize({
                    ...fs,
                    layout: "Portrait"
                  })
                  setsize({
                    ...size,
                    layout: "Portrait"
                  })
                }}>
                  {svg.app.Portrate} <span>Portrait</span>
                </div>
                <div className={"cun_editor_square".concat(size.layout == "Landscape" ? " cun_editor_square_active " : "")} onClick={() => {
                  let fs = allLayout["Landscape"]
                  props.setsize({
                    ...fs,
                    layout: "Landscape"
                  })
                  setsize({
                    ...size,
                    layout: "Landscape"
                  })
                }}>
                  {svg.app.LandScape} <span>Landscape</span>
                </div>
                <div className={"cun_editor_square".concat(size.layout == "Custom" ? " cun_editor_square_active " : "")} onClick={() => {
                  setsize({
                    ...size,
                    layout: "Custom"
                  })
                }}>
                  {svg.app.Custom} <span>Custom</span>
                </div>
              </div>
            </div>
          </div>
          {size.layout == "Custom" ? <>
            <div className="cun_input_wrapper_div_custom_size py-2">
              <label className="cun_image_editor_label pb-1">
                Enter Custom Size{" "}
              </label>
              <div className="cun_input_wrapper">
                <div className="ewp_input ewp_input_size">
                  <input
                    type="number"
                    value={size.width}
                    placeholder="Enter Width"
                    onChange={(e) => {
                      setsize({
                        ...size,
                        width: e.target.value
                      })
                    }}
                  ></input>
                </div>
                <div className="ewp_input ewp_input_size">
                  <input
                    type="number"
                    value={size.height}
                    placeholder="Enter Height"
                    onChange={(e) => {
                      setsize({
                        ...size,
                        height: e.target.value
                      })
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <button className="cun_image_creator_linear_btn mt-1" onClick={() => {
              changeLayout()
            }}> Apply </button>
          </> : <></>}

        </div>
        <div className="ms-0">

          <button className="cun_image_creator_linear_btn mt-2 " onClick={() => {
            changeValue(
              "bgColor",
              `linear-gradient(0deg,#FFFFFF ,#FFFFFF)`
            );
          }}> Remove Background</button>
        </div>



        <div className="cunselectBgTab pt-3"  id="pixastock_div">
          <label className="cun_image_editor_label pb-2">
            Select Background{" "}
          </label>
          <ul className="cun-TabList">
            <li
              className={`tabs ${getActiveClass(1, "active-tabs")}`}
              onClick={() => toggleTab(1)}
            >
              BG Color
            </li>
            <li
              className={`tabs ${getActiveClass(2, "active-tabs")}`}
              onClick={() => toggleTab(2)}
            >
              BG Image
            </li>
            <li
              className={`tabs ${getActiveClass(3, "active-tabs")}`}
              onClick={() => {
                // if(drawData.bgColor.includes("url")){
                //   changeValue(
                //     "bgColor",
                //     `linear-gradient(0deg,#FFFFFF ,#FFFFFF)`
                //   );
                // }

                toggleTab(3)
              }}
            >
              BG Gradient
            </li>
          </ul>
          <div className="cun-settingBg">

            <div className="content-container">

              <div className={`content ${getActiveClass(1, "active-content")} cunContentTab`}>
                <div>
                  <label className="cun_image_editor_label pb-2">
                    {" "}
                    Background color{" "}
                  </label>
                  <div className="cun_input_wrapper_div">

                  {/* <div className="cun_color_picker_wrapper">
                        <div className="cun_color_picker_toggle">
                          <input
                            type="color"

                            value={(drawData.bgColor.includes("url")) ? '#ffffff' : drawData?.bgColor?.split(",")[1]?.trim()}
                            onChange={(e) => {
                              changeValue(
                                "bgColor",
                                `linear-gradient(0deg, ${e.target.value}, ${e.target.value})`
                              );
                            }}
                          />
                          <span>{(drawData.bgColor.includes("url")) ? '#ffffff' : drawData?.bgColor.split(",")[1]}</span>

                        </div>
                      </div> */}

                  </div>
                  {/* <HexColorPicker color={color} onChange={setColor} />
                  <HexColorInput color={color} onChange={setColor} /> */}

                  <div className="pu_color_picker_wrapper">
                    <div ref={menuRef} className="pu_color_picker_toggle" onClick={() => setcolorpicker(!colorPicker)}>
                      <span className="pu_color_preview" style={{ backgroundColor: drawData?.bgColor?.split(",")[1]?.trim() }}></span>
                      <span className="pu_color_name">{drawData?.bgColor?.split(",")[1]?.trim()}</span>
                    </div>
                    {colorPicker && 
                    <div ref={bgRef} className="pu_color_picker_dropdown">
                      <SketchPicker
                        color={drawData?.bgColor?.split(",")[1]?.trim()}
                        onChange={(color) => colorPickerChange('bg_color', color)}
                        onChangeComplete={(color) => colorPickerComplete('bg_color', color)}
                      />
                      <HuePicker
                        color={drawData?.bgColor?.split(",")[1]?.trim()}
                        onChange={(color) => colorPickerChange('bg_color', color)}
                        onChangeComplete={(color) => colorPickerComplete('bg_color', color)}
                        width={276}
                      />
                      <TwitterPicker
                        color={drawData?.bgColor?.split(",")[1]?.trim()}
                        onChange={(color) => colorPickerChange('primary_color', color)}
                        onChangeComplete={(color) => colorPickerComplete('bg_color', color)}
                        width={276}
                        colors={Default_color}
                      />
                    </div>}
                  </div>

                  <div className="cun_image_editor py-2">
                    <label className="cun_image_editor_label pb-2">
                      Default Color{" "}
                    </label>
                    <div className="cun_image_editor_def_color">
                      {Default_color.map((val, i) => (
                        <div
                          key={i + 1}
                          className="cun_image_editor_def_color_box"
                          onClick={() => {
                            changeValue(
                              "bgColor",
                              `linear-gradient(0deg, ${val}, ${val})`
                            );
                          }}
                          style={{ background: `${val}` }}
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`content ${getActiveClass(2, "active-content")} cunContentTab`}>
                <div className="cun_editor_setting_tabs ">

                  <div className="sch-library-tab cun_editorImg-wrap">
                    <Tabs defaultActiveKey="tab-pixastock" id="justify-tab-example" transition={false}>
                      <Tab eventKey="tab-pixastock" title="PixaStock">
                        <div className="sch-library-tab-inner">
                          <PixaStock
                            baseClass="sortPage"
                            mediaType="image"
                            onClickAct={(data) => {
                              changeValue(
                                "bgColor",
                                `url(${data.path})`
                              );
                            }}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="tab-ai-image" title="AI Image">
                        <div className="sch-library-tab-inner">
                          <AiImage />
                        </div>
                      </Tab>
                      <Tab eventKey="tab-apps" title="Apps">
                        <div className="sch-library-tab-inner">
                          <ThirdPartyMedia
                            baseClass="sortPage"
                            mediaType="image"
                            onClickAct={(data) => {
                              changeValue( "bgColor", `url(${data.path})` );
                            }}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="tab-my-library" title="My Library">
                        <div className="sch-library-tab-inner">
                          <Mylibrary
                            dataPerPage='15'
                            baseClass="sortPage"
                            mediaType="image"
                            onClickAct={(data) => {
                              changeValue(
                                "bgColor",
                                `url(${data.path})`
                              );
                            }}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>

                </div>
              </div>
              <div className={`content ${getActiveClass(3, "active-content")} cunContentTab`}>
                <div className="w-100">
                  <div className="py-2">
                    <label className="cun_image_editor_label py-2">Degree </label>

                    <div className="image_editor_range" item>

                      <div className="range">
                        <input
                          type="range"

                          min="0"
                          max="360"
                          onChange={(e) => {
                            setgrad({
                              angle: e.target.value,
                              ...grad
                            });
                            if (drawData.bgColor.includes("url")) {
                              return
                            }
                            changeValue(
                              "bgColor",
                              `linear-gradient(${e.target.value}deg, ${grad?.primary ? grad?.primary : ""},  ${grad?.secondary ? grad?.secondary : ""})`
                            );
                          }}

                        />
                      </div>

                    </div>
                  </div>
                  <div className="cun-setting-Tab-gradient">
                    <label className="cun_image_editor_label py-2 ">
                      {" "}
                      Gradient Color 1
                    </label>
                    {/* <div className="cun_input_wrapper_div pb-3"> */}
                      {/* <div className="cun_color_picker_wrapper">
                        <div className="cun_color_picker_toggle">
                          <input
                            type="color"

                            value={grad?.primary ? grad?.primary?.trim() : '#ffffff'}
                            onChange={(e) => {
                              setgrad({
                                primary: e.target.value,
                                secondary: grad?.secondary,
                                angle: grad?.angle
                              });
                              changeValue("bgColor", `linear-gradient(${grad?.angle}, ${e.target.value}, ${grad?.secondary ? grad?.secondary : "white"}`);

                            }}
                          />
                          <span>{grad.primary ? grad.primary : '#ffffff'}</span>
                        </div>
                      </div> */}
                    {/* </div> */}

                    <div className="pu_color_picker_wrapper">
                    <div ref={pRef} className="pu_color_picker_toggle" onClick={() => setprimaryPicker(!primaryPicker)}>
                      <span className="pu_color_preview" style={{ backgroundColor: grad?.primary ? grad?.primary?.trim() : '#ffffff' }}></span>
                      <span className="pu_color_name">{grad?.primary ? grad?.primary?.trim() : '#ffffff'}</span>
                    </div>
                    {primaryPicker && 
                    <div ref={primaryRef} className="pu_color_picker_dropdown">
                      <SketchPicker
                        color={grad?.primary ? grad?.primary?.trim() : '#ffffff'}
                        onChange={(color) => colorPickerChange('primary_color', color)}
                        onChangeComplete={(color) => colorPickerComplete('primary_color', color)}
                      />
                      <HuePicker
                        color={grad?.primary ? grad?.primary?.trim() : '#ffffff'}
                        onChange={(color) => colorPickerChange('primary_color', color)}
                        onChangeComplete={(color) => colorPickerComplete('primary_color', color)}
                        width={276}
                      />
                      <TwitterPicker
                        color={grad?.primary ? grad?.primary?.trim() : '#ffffff'}
                        onChange={(color) => colorPickerChange('primary_color', color)}
                        onChangeComplete={(color) => colorPickerComplete('primary_color', color)}
                        width={276}
                        colors={Default_color}
                      />
                    </div>}
                  </div>

                  </div>
                  <div className="cun-setting-Tab-gradient">
                    <label className="cun_image_editor_label pb-2 py-2">
                      {" "}
                      Gradient Color 2
                    </label>
                    {/* <div className="cun_input_wrapper_div pb-3"> */}

                      {/* <div className="cun_color_picker_wrapper">
                        <div className="cun_color_picker_toggle">
                          <input
                            type="color"
                            value={grad.secondary ? grad.secondary?.trim() : '#ffffff'}
                            onChange={(e) => {
                              setgrad({
                                primary: grad?.primary,
                                secondary: e.target.value,
                                angle: grad?.angle
                              });
                              changeValue("bgColor", `linear-gradient(${grad?.angle}, ${grad?.primary ? grad?.primary : "white"}, ${e.target.value})`);

                            }}
                          />
                          <span>{grad.secondary ? grad.secondary : '#ffffff'}</span>
                        </div>
                      </div> */}
                    {/* </div> */}

                    <div className="pu_color_picker_wrapper">
                    <div ref={sRef} className="pu_color_picker_toggle" onClick={() => setsecondaryPicker(!secondaryPicker)}>
                      <span className="pu_color_preview" style={{ backgroundColor: grad.secondary ? grad.secondary?.trim() : '#ffffff' }}></span>
                      <span className="pu_color_name">{grad.secondary ? grad.secondary?.trim() : '#ffffff'}</span>
                    </div>
                    {secondaryPicker && 
                    <div className="pu_color_picker_dropdown" ref={secondaryRef}>
                      <SketchPicker
                        color={grad.secondary ? grad.secondary?.trim() : '#ffffff'}
                        onChange={(color) => colorPickerChange('secondary_color', color)}
                        onChangeComplete={(color) => colorPickerComplete('secondary_color', color)}
                      />
                      <HuePicker
                        color={grad.secondary ? grad.secondary?.trim() : '#ffffff'}
                        onChange={(color) => colorPickerChange('secondary_color', color)}
                        onChangeComplete={(color) => colorPickerComplete('secondary_color', color)}
                        width={276}
                      />
                      <TwitterPicker
                        color={grad.secondary ? grad.secondary?.trim() : '#ffffff'}
                        onChange={(color) => colorPickerChange('secondary_color', color)}
                        onChangeComplete={(color) => colorPickerComplete('secondary_color', color)}
                        width={276}
                        colors={Default_color}
                      />
                    </div>}
                  </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Settings;
